<style>
.input-readonly>div>.vs-inputx {
    background-color: #e6e6e6;
}
</style> 
<template>
    <vx-card title="AR Write Off">
        <div class="vx-row mb-12">

            <div class="vx-col w-full ">
                <div class="vx-row mb-2">
                    <div class="vx-col w-1/2">
                        <vs-button type="line" icon-pack="feather" color="Red" icon="icon-arrow-left" v-on:click.stop="
                            handleBack()" />
                    </div>
                    <div class="vx-col w-1/2">

                    </div>
                </div>

                <div class="vx-row mb-2">
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label">Transaction Type</label>
                        <multiselect class="selectExample" v-model="create.transactionType"
                            :options="option.transactionType" :multiple="false" :group-select="false" label="name"
                            @input="transactionTypeSelected" track-by="name" placeholder=" Type to search">
                        </multiselect>
                    </div>
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label">Operating Unit</label>
                        <multiselect class="selectExample" v-model="create.operatingUnit" :options="option.operatingUnit"
                            track-by="operating_unit_id" label="operating_unit_name" :multiple="false" :allow-empty="false"
                            :group-select="false" :max-height="160" :limit="4" placeholder=" Type to search"
                            @input="operatingUnitSelected" :disabled="true">
                            <template slot="singleLabel" slot-scope="dt">
                                <span class="option__desc">
                                    <span class="option__title">{{
                                        dt.option.operating_unit_code + ' ' +
                                        dt.option.operating_unit_name
                                    }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="dt">
                                <div class="option__desc">
                                    <span class="option__title">{{
                                        dt.option.operating_unit_code + ' ' +
                                        dt.option.operating_unit_name
                                    }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
              
                
                </div>

                <div class="vx-row mb-2">
                    <div class="vx-col w-1/2">
                        <!-- <div class="vx-col w-full">
                                <label class="vs-input--label">AR write-off Type</label>
                                <multiselect class="selectExample" v-model="create.type" :options="option.type"
                                    :multiple="false" :group-select="false" :max-height="160" :limit="4" label="name"
                                    track-by="name" placeholder=" Type to search">
                                </multiselect>
                        </div> -->
                         <label class="vs-input--label">Code</label>
                         <vs-input class="w-full" readonly v-model="create.code" />
                    </div>
                    <!-- <div class="vx-col w-1/2">
                        <label class="vs-input--label">Distribution Channel</label>
                        <multiselect class="selectExample" v-model="create.distChannel" :options="option.distChannel"
                            :multiple="false" :allow-empty="true" :group-select="false" :max-height="160"
                            :limit="4" :loading="loading.distChannel" placeholder=" Type to search" track-by="ID"
                            label="name">
                        </multiselect>
                    </div> -->
                        <!-- <multiselect class="selectExample" v-model="create.distributionChannel"
                            :options="option.distributionChannel" :multiple="false" :group-select="false" label="name"
                            @input="distributionChannelSelected" track-by="name" placeholder=" Type to search">
                        </multiselect> -->
                    
                        <!-- <div class="vx-col w-1/2">
                            <div class="vx-row w-full"> -->
                                <!-- <div class="vx-col w-full">
                                    <label class="vs-input--label">Salesman</label>
                                    <multiselect class="selectExample" v-model="create.salesman" :options="option.salesman"
                                        :multiple="false" :allow-empty="true" :group-select="false" :max-height="160"
                                        :limit="4" :loading="loading.salesman" placeholder=" Type to search" track-by="ID"
                                        label="Name" @search-change="getOptionSalesman">
                                    </multiselect>
                                </div>
                                <div class="vx-col w-full">
                                    <label class="vs-input--label">Customer</label>
                                    <multiselect class="selectExample" v-model="create.customer" :options="option.customer"
                                        :multiple="false" :allow-empty="true" :group-select="false" :max-height="160"
                                        :limit="4" placeholder=" Type to search" track-by="ID" label="name"
                                        :loading="loading.customer" @search-change="getOptionCustomer">
                                    </multiselect>
                                </div>
                                <div class="vx-col w-full">
                                    <label class="vs-input--label">Aging (More Than)</label>
                                    <vs-input class="w-full" v-model="create.aging" />
                                </div>
                                <div class="vx-col w-full">
                                    <label class="vs-input--label">Value (Less Than)</label>
                                    <vs-input class="w-full" v-model="create.value"
                                        @keyup="create.value = formatPrice(create.value.toString())" />
                                </div>
                                <div class="vx-col w-1/2">
                                    <label class="vs-input--label">Operation</label>
                                    <multiselect class="selectExample" v-model="create.operation"
                                        :options="option.operation" :multiple="false" :group-select="false"
                                        :max-height="160" :limit="4" label="name" track-by="name"
                                        placeholder=" Type to search">
                                    </multiselect>
                                </div>
                                <div class="vx-col w-1/2">
                                    <label class="vs-input--label"></label>
                                    <vs-button type="line" icon-pack="feather" color="Green" icon="icon-search"
                                        v-on:click.stop="
                                            getDocument(tr)" />
                                </div> -->

                            <!-- </div>

                        </div>
                         -->
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col w-1/2">
                            <label class="vs-input--label">Note / Reason</label>
                            <vs-textarea class="w-full" label="Note"  v-model="create.note" />
                        </div>
                        <div class="vx-col w-1/2">
                            <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
                                <div class="vx-col sm:w-1/3 w-full">
                                    <span>File</span>
                                </div>
                                <div class="vx-col sm:w-2/3 w-full">
                                    <div class="vx-col sm:w-4/5 w-full">
                                        <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file"
                                            multiple="multiple" accept=".jpg, .jpeg, .png, .pdf" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="vx-col sm:w-2/3 w-full">
                                    <div class="vx-col sm:w-4/5 w-full">
                                        <vs-button class="mr-3 mb-2" @click="handleAttachment">Add Attachment</vs-button>
                                    </div>
                                </div>
                            </div>
                            <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
                                <vs-divider>List Attachment</vs-divider>
                                <table class="vs-table vs-table--tbody-table">
                                    <template v-for="(tr) in fileAttachment">
                                        <tr class="tr-values vs-table--tr tr-table-state-null selected"
                                            v-bind:key="tr.NameFile">
                                            <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                            <td class="td vs-table--td">
                                                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                                                    <vs-button type="line" icon-pack="feather" icon="icon-eye"
                                                        @click.stop="handleShowAttachment(tr)" />
                                                </vx-tooltip>
                                            </td>
                                            <!-- <td class="td vs-table--td">
                                                <template>
                                                    <vx-tooltip text="Delete">
                                                        <vs-button type="line" icon-pack="feather" icon="icon-trash"
                                                            @click.stop="handleDeleteAttachment(i)" />
                                                    </vx-tooltip>
                                                </template>
                                            </td> -->
                                        </tr>
                                    </template>
                                </table>
                                <vs-divider></vs-divider>


                            </div>
                        </div>
                    </div>

                <!-- </div> -->

            </div>

            <div>
                <div>
                    <vs-prompt color="primary" title="Confirmation" @cancel="approvePrompt = false" @accept="doApprove"
                        @close="closeConfirm" :active.sync="approvePrompt">
                        <div class="con-exemple-prompt">
                            Are you sure to approve ?
                            <br />
                            <br />
                        </div>
                    </vs-prompt>
                    <vs-prompt color="primary" title="Confirmation" @cancel="rejectPrompt = false" @accept="doReject"
                        @close="closeConfirm" :active.sync="rejectPrompt">
                        <div class="con-exemple-prompt">
                            Are you sure to reject ?
                            <br />
                            <br />
                        </div>
                    </vs-prompt>
                </div>
            </div>

            <div style="display:none" v-bind:class="[
                checked.length > 0 ? detailShow + ' vertical-divider' : '',
                detailHide,
            ]">
                <data-table :responseData="responseData" :propsParams="params" :header="header"
                    @reloadDataFromChild="reloadData">
                    <template slot="thead">
                        <th width="7%">
                            -
                        </th>
                    </template>
                    <template slot="tbody">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td class="whitespace-no-wrap">
                                <vs-checkbox color="success"
                                    :checked="checkedID.includes(paramDetail.type_doc + '-' + tr.ID)"
                                    v-on:click="addChecked(paramDetail.type_doc + '-' + tr.ID, tr.ID)"></vs-checkbox>
                        </vs-td>
                        <template v-if="paramDetail.type_doc == '01'">
                                <vs-td>
                                    {{ tr.SalesmanName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.ClientCode }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.ClientName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Code }}
                                </vs-td>
                                <vs-td>
                                    {{ globalDateFormat(tr.Date) }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Aging }} Days
                                </vs-td>
                                <vs-td>
                                    {{ priceFormat(parseFloat(tr.Remaining)) }}
                                </vs-td>
                                <vs-td>
                                    {{ priceFormat(parseFloat(tr.Provision)) }}
                                </vs-td>
                                <vs-td>
                                    {{ priceFormat(parseFloat(tr.Expense)) }}
                                </vs-td>

                            </template>
                            <template v-if="paramDetail.type_doc == '02'">
                                <vs-td>
                                    {{ tr }}
                                </vs-td>

                        </template>
                        <template v-if="paramDetail.type_doc == '03'">
                                <vs-td>
                                    {{ tr.SalesmanName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.CustomerCode }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.CustomerName }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Code }}
                                </vs-td>
                                <vs-td>
                                    {{ globalDateFormat(tr.Date) }}
                                </vs-td>
                                <vs-td>
                                    {{ tr.Aging }} Days
                                </vs-td>
                                <vs-td>
                                    {{ priceFormat(parseFloat(tr.TotalValue - tr.UsedValue)) }}
                                </vs-td>
                                <vs-td>
                                    {{ priceFormat(parseFloat(tr.Provision)) }}
                                </vs-td>                   
                                <vs-td>
                                    {{ priceFormat(parseFloat(tr.Expense)) }}
                                </vs-td>          

                            </template>

                        </vs-tr>
                    </template>
                </data-table>
                <!-- Approve Prompt -->

            </div>
            <!-- form -->
            <transition name="detail-fade">
                <div v-if="checked.length > 0" v-bind:class="[
                    checked.length > 0 ? detailShow + 'vx-col md:w-1/1 w-full  mb-base' : '',
                    detailHide,
                ]">
                   

                        <vs-table stripe max-items="10" pagination :data="checked">

                            <template slot="thead">
                                
                                <vs-th>Salesman</vs-th>
                                <vs-th>Customer Codes</vs-th>
                                <vs-th>Customer Name</vs-th>
                                <vs-th>Code</vs-th>
                                <vs-th>Date</vs-th>
                                <vs-th>Aging</vs-th>
                                <vs-th>Assignment</vs-th>
                                <vs-th>Value</vs-th>
                                <vs-th>% Provision</vs-th>
                                <vs-th>% Expense</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                   
                                   <template v-if="paramDetail.type_doc == '01'">
                                            <vs-td>
                                                {{ tr.SalesmanName }}
                                            </vs-td>
                                           <vs-td>
                                               {{ tr.ClientCode }}
                                           </vs-td>
                                           <vs-td>
                                               {{ tr.ClientName }}
                                           </vs-td>
                                           <vs-td>
                                               {{ tr.Code }}
                                           </vs-td>
                                           <vs-td>
                                               {{ globalDateFormat(tr.Date) }}
                                           </vs-td>
                                           <vs-td>
                                               {{ tr.Aging }} Days
                                           </vs-td>
                                           <vs-td>
                                               {{ tr.Assignment }}
                                           </vs-td>
                                           <vs-td>
                                               {{ priceFormat(parseFloat(tr.Remaining)) }}
                                           </vs-td>
                                           <vs-td>
                                               {{ priceFormat(parseFloat(tr.Provision)) }}
                                           </vs-td>
                                           <vs-td>
                                               {{ priceFormat(parseFloat(tr.Expense)) }}
                                           </vs-td>
   
                                       </template>
                                       <template v-if="paramDetail.type_doc == '02'">
                                        <vs-td>
                                                {{ tr.SalesmanName }}
                                            </vs-td>
                                           <vs-td>
                                           {{ tr.PersonCode }}
                                       </vs-td>
                                       <vs-td>
                                           {{ tr.PersonName }}
                                       </vs-td>
                                       <vs-td>
                                           {{ tr.Code }}
                                       </vs-td>
                                       <vs-td>
                                           {{ globalDateFormat(tr.Date) }}
                                       </vs-td>
                                       <vs-td>
                                           {{ tr.Aging }} Days
                                       </vs-td>
                                       <vs-td>
                                            {{ tr.Assignment }}
                                       </vs-td>
                                       <vs-td>
                                           {{ priceFormat(parseFloat(tr.DepositValueRaw)) }}
                                       </vs-td>
                                       <vs-td>
                                            {{ priceFormat(parseFloat(tr.Provision)) }}
                                       </vs-td>
                                       <vs-td>
                                               {{ priceFormat(parseFloat(tr.Expense)) }}
                                       </vs-td>
   
                                   </template>
                                   <template v-if="paramDetail.type_doc == '03'">
                                            <vs-td>
                                                {{ tr.SalesmanName }}
                                            </vs-td>
                                           <vs-td>
                                               {{ tr.CustomerCode }}
                                           </vs-td>
                                           <vs-td>
                                               {{ tr.CustomerName }}
                                           </vs-td>
                                           <vs-td>
                                               {{ tr.Code }}
                                           </vs-td>
                                           <vs-td>
                                               {{ globalDateFormat(tr.Date) }}
                                           </vs-td>
                                           <vs-td>
                                               {{ tr.Aging }} Days
                                           </vs-td>
                                           <vs-td>
                                               {{ tr.Assignment }}
                                           </vs-td>
                                           <vs-td>
                                               {{ priceFormat(parseFloat(tr.TotalValue - tr.UsedValue)) }}
                                           </vs-td>
                                           <vs-td>
                                               {{ priceFormat(parseFloat(tr.Provision)) }}
                                           </vs-td>
                                           <vs-td>
                                               {{ priceFormat(parseFloat(tr.Expense)) }}
                                           </vs-td>
   
                                       </template>
   
   
                                   </vs-tr>
                            </template>
                        </vs-table>
                    
                    <vs-row>
                        <div class="mt-4 flex justify-between">

                        <vs-button color="primary" type="filled" class="mr-4" v-on:click="approvePromptTrue()">Approve</vs-button>
                        <vs-button color="red" type="filled" v-on:click="rejectPromptTrue()">Reject</vs-button>
                        </div>

                    </vs-row>
                </div>
            </transition>
        </div>
    </vx-card>
</template>
<script>
import moment from "moment"

// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
    components: {
        
    },
    props: {
        selected: Object,
    },
    data() {
        return {
            option: {
                transactionType: [
                    { name: 'AR INV', value: '01' },
                    { name: 'Cust Deposit', value: '02' },
                    { name: 'Credit Note', value: '03' },
                ],
                type: [
                    { name: 'Allowance', value: '0' },
                    { name: 'Expense', value: '1' },
                ],
                operation: [
                    { name: 'Or', value: 0 },
                    { name: 'And', value: 1 },
                ],
                territory: [],
                operatingUnit: [],
                salesman: [],
                customer: [],
                distChannel: [],
                
            },
            loading: {
                salesman: false,
                distChannel: false,
            },
            counterLoading: {
                salesman: 0,
                customer: 0,
                distChannel: 0,
            },
            params: {
                search: "",
                length: 10,
                page: 1,
                order: "desc",
                sort: "id",
            },
            optionMethod: [],
            activePromptConfrm: false,
            header: [
                {
                    text: "Salesman",
                    value: "salesman",
                    // width: '5%'
                },
                {
                    text: "Customer Code",
                    value: "customer_code",
                    // width: '5%'
                },
                {
                    text: "Customer Name",
                    value: "customer_name",
                },
                {
                    text: "Code",
                    value: "code",
                },
                {
                    text: "Date",
                    value: "date",
                },
                {
                    text: "Aging",
                    sortable: false,
                },
                {
                    text: "Value",
                    sortable: false,
                }
            ],
            data: [],
            checked: [],
            checkedID: [],
            paramDetail: {},
            optionOU: [],
            operatingUnit: {},
            methods: ['', 'Cash', 'Giro', 'Cheque', 'Transfer'],
            responseData: {},
            create: {
                transactionType: null,
                type: null,
                method: "",
                operatingUnit: null,
                salesman: null,
                customer: null,
                value: 0,
                aging: 0,
                operation: null,
                distChannel: null,
            },
            detailShow: "core vx-col md:w-1/1 w-full mb-base",
            detailHide: "core vx-col md:w-1/1 w-full mb-base",
            detail: false,
            notes: "",
            bankStatement: {},
            approveOrReject: false,
            approvePrompt: false,
            rejectPrompt: false,
            selectedData: {},
            bankStatementID: null,
            fileAttachment: [],
            
        };
    },
    computed: {

        // operatingUnit(){
        //   console.log(this.operatingUnit,"ou")
        // }
    },
    watch: {
        operatingUnit() {
            // console.log(this.operatingUnit, "ou")
            this.reloadData(this.params)
            this.handleClose()
        },
    },
    mounted() {
        // console.log(this.$route.params.id)
        // this.bankStatementID = this.$route.params.id
        // // this.reloadData(this.$route.params.id);
        
        this.getOptionOU()
        this.getOptionDistChannel()
        // this.page(1);
        // this.dataId = this.data[0].id
    },
    methods: {
        approvePromptTrue () {
            this.approvePrompt = true
        },
        rejectPromptTrue () {
            this.rejectPrompt = true
        },
        getData () {

            this.$http.get('/api/v1/write-off/' + this.$route.params.id).then((r) => {
                if (r.code == 200) {
                    // console.log(r)
                    const records = r.data.records
                    const recordLines = r.data.lines
                    if (records.Status != 3) {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: records.Code +" cannot edit",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$router.push("/finance/write-off/approval");
                        return
                    }
                    const type = this.option.type.filter(v => {
                        // console.log(v,records)
                        return v.value == records.Type
                    })
                    
                    this.create.type = type[0]

                    const transactionType = this.option.transactionType.filter(v => {
                        return v.value == records.TransactionType
                    })
                    this.create.code = records.Code
                    this.create.note = records.Note
                    this.fileAttachment = records.Attachment
                    this.create.transactionType = transactionType[0]
                    
                    const territoryCode = this.option.operatingUnit.filter(v => {
                        return v.operating_unit_code == records.OperatingUnitCode && v.territory_code == records.TerritoryCode
                    })
                    this.create.operatingUnit = territoryCode[0]

                    let param = {
                        salesman: this.create.salesman == null ? 0 : this.create.salesman.ID,
                        type_doc: this.create.transactionType.value,
                        customer: this.create.customer == null ? 0 : this.create.customer.ID,
                    };
                    this.paramDetail = param
                    let checked = []
                    let checkedID = []
                    // console.log(recordLines,'recordLines')
                    recordLines.map(v => {
                        // console.log('vvv',v)
                        checkedID.push(param.type_doc + '-' + v.ReferenceID)
                        let array = {}
                        if (param.type_doc == "03") {
                            array = {
                                ID: v.ReferenceID,
                                Code: v.ReferenceCode,
                                CustomerName: v.PersonName,
                                CustomerCode: v.PersonCode,
                                CustomerID: v.PersonID,
                                Date: v.ReferenceDate,
                                Aging: v.Aging,
                                TotalValue: v.RemainingValue,
                                UsedValue: 0,
                                Provision: v.Provision,
                                Expense: v.Expense,
                                Assignment: v.Assignment,
                                SalesmanName: v.SalesmanName,
                                SalesmanID: v.SalesmanID,
                            }
                        } else if (param.type_doc == "01") {
                            array = {
                                ID: v.ReferenceID,
                                Code: v.ReferenceCode,
                                ClientName: v.PersonName,
                                ClientCode: v.PersonCode,
                                ClientID: v.PersonID,
                                Date: v.ReferenceDate,
                                Aging: v.Aging,
                                Remaining: v.RemainingValue,
                                UsedValue: 0,
                                Provision: v.Provision,
                                Expense: v.Expense,
                                Assignment: v.Assignment,
                                SalesmanName: v.SalesmanName,
                                SalesmanID: v.SalesmanID,
                            }
                        }  else if (param.type_doc == "02") {
                            array = {
                                ID: v.ReferenceID,
                                Code: v.ReferenceCode,
                                PersonName: v.PersonName,
                                PersonCode: v.PersonCode,
                                PersonID: v.PersonID,
                                Date: v.ReferenceDate,
                                Aging: v.Aging,
                                Remaining: v.RemainingValue,
                                DepositValueRaw: v.RemainingValue,
                                Provision: v.Provision,
                                Expense: v.Expense,
                                Assignment: v.Assignment,
                                SalesmanName: v.SalesmanName,
                                SalesmanID: v.SalesmanID,
                            }
                        }
                        checked.push(array)

                    })
                    this.checked = checked
                    this.checkedID = checkedID
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: r.message,
                        text: "error catch",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    this.handleBack()
                }
            }).catch((e) => {
                console.log(e)
                this.$vs.notify({
                    color: "danger",
                    title: "error catch",
                    text: "error catch",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.handleBack()
            })
        },  

        addChecked(key, id) {
            // console.log(this.checkedID.length);
            if (this.checkedID.includes(key)) {
                this.checkedID.splice(this.checkedID.indexOf(key), 1);
                this.checked = this.checked.filter(v => {
                    return v.ID != id
                })
            } else {
                this.checkedID.push(key);
                let filter = this.data.filter(v => {
                    return v.ID == id
                })
                this.checked.push(filter[0])
            }
        },
       
        transactionTypeSelected() {

        },
        handleBack() {
            this.$router.push("/finance/write-off/approval");
        },
        getOptionOU() {
            this.$http.get("/api/v1/master/operating-unit").then((resp) => {
                this.option.territory = []
                this.option.operatingUnit = []
                resp.data.records.map(r => {
                    let array = {
                        operating_unit_id: r.ID,
                        operating_unit_code: r.Code,
                        operating_unit_name: r.Name,
                        territory_id: r.OperatingUnitTerritory.TerritoryID,
                        territory_code: r.OperatingUnitTerritory.Territory.code,
                        territory_name: r.OperatingUnitTerritory.Territory.name,
                    }
                    // console.log(this.create.operatingUnit)
                    this.option.territory.push(array)
                    this.option.operatingUnit.push(array)
                })
                this.immutable = false
                this.getData()
                // this.optionOU = resp.data.records;
                // this.operatingUnit = resp.data.records[0];
            });

        },
        dateFormat(value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },
        operatingUnitSelected() {
            this.create.customer = null
            this.create.salesman = null
            this.checked = []
            this.checkedID = []
        },
        distributionChannelSelected() {
            this.create.customer = null
            this.create.salesman = null
            this.checked = []
            this.checkedID = []
        },
        generateWriteOff() {
            this.activePromptConfrm = true 
        },
        closeConfirm () {
            this.activePromptConfrm = false
        },
        doApprove() {
            this.$vs.loading();
            this.$http.post('/api/v1/write-off/approve/' + this.$route.params.id, {
               note: this.create.note 
            })
                .then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else if (r.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                        this.$router.push("/finance/write-off/approval");
                        // this.reloadData(this.params)
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                    }
                });
        },
        doReject() {
            this.$vs.loading();
            this.$http.post('/api/v1/write-off/reject/' + this.$route.params.id,  {
               note: this.create.note 
            })
                .then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else if (r.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                        this.$router.push("/finance/write-off/approval");
                        // this.reloadData(this.params)
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                    }
                });
        },
        handleShowAttachment(file) {
            return this.downloadFileAwsS3(file.PathFile)
            // let objectURL
            // console.log(file)
            // objectURL = URL.createObjectURL(file.File);

            // // link.download = file.name; // this name is used when the user downloads the file
            // var href = objectURL;
            // window.open(href, '_blank').focus();
        },
         getOptionDistChannel(query) {
            // if (this.create.operatingUnit == null) {
            //     this.$vs.notify({
            //         color: "danger",
            //         title: "Required",
            //         text: "Operating Unit is required",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-x-circle",
            //     });
            //     return
            // }
            // console.log(query);
            let param = {
                search: query,
            };
            // console.log(param)

            this.loading.distChannel = true
            this.counterLoading.distChannel++
            this.$http.get('/api/v1/write-off/distribution-channel', {
                params: param,
            }).then((r) => {
                // console.log(r);
                this.option.distChannel = r.data.distributionChannel

                this.counterLoading.distChannel--
                if (this.counterLoading.distChannel == 0) {
                    this.loading.distChannel = false
                }
                // console.log(r)
            }).catch((e) => {
                console.log(e)
            })
        },

        handleDeleteAttachment(id) {
            // const deleteAttachment =this.fileAttachment[index]
            // this.fileAttachment = this.fileAttachment.filter((r, i) => {
            //     return i != index
            // })
            this.$vs.loading();
            var form = new FormData();
            form.append("list_id", id);
            form.append("id", this.$route.params.id);
            this.$http
                .post("/api/v1/write-off/delete-file", form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        console.log(this.fileAttachment)
                        console.log(id)
                        this.fileAttachment = this.fileAttachment.filter(v => {
                            return v.ListID != id
                        })
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        reloadData(params) {
            console.log(Object.keys(this.paramDetail).length, 'aaaaaa')
            if (Object.keys(this.paramDetail).length === 0) {
                this.responseData.length = 0;
                this.responseData.recordsTotal = 0;
                return
            }
            this.paramDetail.page = params.page;
            this.paramDetail.length = params.length;
            this.paramDetail.search = params.search;
            this.$vs.loading();
            this.$http.get('/api/v1/write-off/document', {
                params: this.paramDetail,
            })
                .then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                    } else if (r.code == 200) {
                        this.$vs.loading.close();
                        this.data = r.data.records;
                        this.responseData = r.data;
                        this.responseData.length = r.data.length;
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleAttachment() {

            if (this.$refs.file.files.length > 0) {
                this.lengthFile = this.$refs.file.files.length
                this.file = this.$refs.file.files;
                // for(let i =0; i < this.$refs.file.files.length; i++) {

                this.uploadData(0);
                // }

            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
        uploadData(array) {
            console.log(this.lengthFile, this.file, array)
            if (this.lengthFile <= array) {
                // this.$emit("closeDetail", true);
                // this.$vs.loading.close();
                document.querySelector("#fileInput").value = "";
                return
            }
            this.$vs.loading();
            this.$http
                .post("/api/v1/write-off/upload-file", this.paramUpload(array), {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((resp) => {

                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.fileAttachment = []
                        resp.data.attachment.map(v => {
                            this.fileAttachment.push({
                                ListID: v.ListID,
                                NameFile: v.NameFile,
                                PathFile: v.PathFile,
                            });
                        })
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });

                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.uploadData(++array)
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                    this.uploadData(++array)
                });
        },
        paramUpload(array) {
            var form = new FormData();
            form.append("id", this.$route.params.id);
            form.append("file", this.file[array]);
            return form;
        },
        getDocument() {
            if (this.create.operatingUnit == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Operating Unit is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.transactionType == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Transaction  type is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.operation == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Operation  type is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.aging <= 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Aging must be greater then 0",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (this.create.value <= 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Value must be greater then 0",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            let param = {
                salesman: this.create.salesman == null ? 0 : this.create.salesman.ID,
                type_doc: this.create.transactionType.value,
                customer: this.create.customer == null ? 0 : this.create.customer.ID,
                aging: this.create.aging,
                value: this.create.value.replace(/,/g, ''),
                length: 10,
                page: 1,
                operation: this.create.operation.value,
                territory_id: this.create.operatingUnit.territory_id,

            };
            if (Object.keys(this.paramDetail).length !== 0) {
                if (this.paramDetail.type_doc != param.type_doc) {
                    this.checked = []
                    this.checkedID = []
                }
            }
            this.$vs.loading();
            this.$http.get('/api/v1/write-off/document', {
                params: param,
            }).then((r) => {
                if (r.code == 500) {
                    this.$vs.loading.close();
                } else if (r.code == 200) {
                    this.$vs.loading.close();
                    this.paramDetail = param

                    this.data = r.data.records;
                    this.responseData = r.data;
                    this.responseData.length = r.data.length;

                } else {
                    this.$vs.loading.close();

                }
            }).catch((e) => {
                console.log(e)
            })
        },
        getOptionSalesman(query) {
            if (this.create.operatingUnit == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Operating Unit is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (query.length <= 2) {
                return
            }
            let param = {
                territory_id: this.create.operatingUnit.territory_id,
                search: query,
            };
            this.loading.salesman = true
            this.counterLoading.salesman++
            this.$http.get('/api/v1/write-off/salesman', {
                params: param,
            }).then((r) => {
                this.option.salesman = r.data.salesman
                this.counterLoading.salesman--
                if (this.counterLoading.salesman == 0) {
                    this.loading.salesman = false
                }
                // console.log(r)
            }).catch((e) => {
                console.log(e)
            })
        },
        getOptionCustomer(query) {
            if (this.create.operatingUnit == null) {
                this.$vs.notify({
                    color: "danger",
                    title: "Required",
                    text: "Operating Unit is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return
            }
            if (query.length <= 2) {
                return
            }
            let param = {
                territory_id: this.create.operatingUnit.territory_id,
                search: query,
            };

            this.loading.customer = true
            this.counterLoading.customer++
            this.$http.get('/api/v1/write-off/customer', {
                params: param,
            }).then((r) => {
                this.option.customer = r.data.customer

                this.counterLoading.customer--
                if (this.counterLoading.customer == 0) {
                    this.loading.customer = false
                }
                // console.log(r)
            }).catch((e) => {
                console.log(e)
            })
        },
        dataTable(params) {
            // console.log(this.operatingUnit, 'dttb')
            return new Promise((resolve, reject) => {
                this.$http.get('/api/v1/bank-statement/line/data-table/' + this.bankStatementID, {
                    params: {
                        status: 0,
                        search: params.search,
                        length: params.length,
                        page: params.page,
                        order: params.sort,
                        sort: params.order,
                        // territory_id: this.operatingUnit ? this.operatingUnit.OperatingUnitTerritory.TerritoryID : 0,
                        // operating_unit_id: this.operatingUnit ? this.operatingUnit.ID : 0,
                    }
                }).then((r) => {
                    resolve(r)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        
        handleClose() {
            this.reloadData(this.params)
            this.selectedData.class = ""
            this.detail = false
            this.selectedData = {
                ID: 0,
            };
        },
        formatPrice(angka, prefix = "") {
            return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

    },
};
</script>
<style scoped>
.nonfixed {
    position: inherit;
    padding-left: 20px;
}

.core-enter-active {
    transition: all 0.3s ease;
}

.core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.core {
    -webkit-transform-origin-y: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-right: 1px solid #7367f0;
    /* min-height: 800px; */
    /* height: 100%; */
    -webkit-mask-position-y: fixed;
    /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
    background: beige;
}

.highlight>td {
    background-color: #d0cdf0;
}

.colored {
    border: 1px solid #7367f0;
    position: fixed;
    left: 4%;
    top: 40%;
    max-width: 45%;
    z-index: 999999999999;
    background: antiquewhite;
    background-color: antiquewhite;
}

.vs-dialog {
    max-width: 1000px;
}
</style>
